<template>
  <div v-if="started">
    <v-container class="quizBorder" style="background: #f0f2f5">
      <v-container
        v-for="cat in categories"
        :key="cat.id"
        class="mx-auto max-width tile px-0"
        style="height: 680px; overflow-y: scroll"
      >
        <h3 class="mb-2">{{ cat.name }} {{ _getUserId() }}</h3>
        <v-card
          v-for="question in cat.questions"
          :key="question.id"
          class="mb-4"
        >
          <v-list>
            <h4 class="text-wrap pa-2">
              {{ question.name }}
              <span
                class="secondary--text float-right"
                v-if="question.score != null"
                >({{ question.score }})</span
              >
              <small v-else class="red--text">оноогүй</small>
            </h4>

            <v-list-item
              class="px-1"
              v-for="answer in question.answers"
              :key="answer.id"
              @click="answerDefaultQuestion(question, answer, category)"
            >
              <v-list-item-avatar class="mr-0">
                <v-icon
                  v-if="
                    question.answered &&
                    question.useranswer &&
                    question.useranswer.answerId == answer.id
                  "
                  style="color: red"
                >
                  mdi-checkbox-marked-circle
                </v-icon>
                <v-icon v-else> mdi-checkbox-blank-circle-outline </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title
                  class="text-wrap"
                  v-text="answer.name + ': ' + answer.id"
                />
              </v-list-item-content>

              <v-list-item-action v-if="answer.correct">
                <v-btn icon>
                  <v-icon color="green lighten-3"> mdi-information </v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-container>
      <v-row justify="center" class="mt-1 pb-1" style="background: #e89e57">
        <v-btn
          class="ma-2 px-4"
          :loading="loading"
          :disabled="loading"
          color="secondary"
          @click="_finish"
        >
          Дуусгаx
        </v-btn>
      </v-row>
    </v-container>
  </div>
  <v-container
    v-else
    class="text-center quizBorder"
    style="background: #fff; overflow-y: hidden"
  >
    <v-container style="height: 78vh">
      <div style="height: 24vh"></div>
      <p style="font-size: 36pt; display: block">Quiz: {{ asuulga.name }}</p>
      <p style="font-size: 24pt">
        үргэжлүүлэx бол ЭXЛЭX товчийг дарж эxлүүлнэ үү!
      </p>
    </v-container>
    <v-row justify="end" style="background: #e89e57">
      <v-btn
        style="font-size: 15px; font-weight: bold"
        class="ml-10 mt-10 px-10 white--text"
        :loading="loading"
        :disabled="loading"
        color="secondary"
        @click="_start"
      >
        Эxлэx
        <i
          class="mdi mdi-chevron-right"
          style="font-size: 30px"
          aria-hidden="true"
        ></i>
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
const fb = require("../../firebaseConfig.js");
export default {
  components: {},
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      // return value.charAt(0).toUpperCase() + value.slice(1)
      return value.toUpperCase();
    },
  },
  props: {
    asuulgaId: {
      type: String,
      required: false,
    },
  },

  data: () => ({
    started: false,
    loader: null,
    loading: false,
    selectedQuestion: null,
    asuulga: null,
    category: null,
    selectedTabIndex: 0,
    links: ["Questions", "Participants", "Responses"],
    categories: [],
    userProps: null,
    currentIndex: 0,
  }),
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      this._finish();
      setTimeout(() => (this[l] = false), 3000);

      this.loader = null;
    },
  },
  created() {
    this.createNewUser();
    this._findCategories();
    // if (this.categories != null && this.categories.length > 0)
    //             this.category = this.categories[0];
  },
  methods: {
    _start() {
      this.started = true;
    },
    async _finish() {
      console.log(this._getUserId());
      var allScore = 0;
      var allScored = 0;
      const fbAnswered = await fb.db
        .collection(
          "asuulga/" +
            this.asuulgaId +
            "/users/" +
            this._getUserId() +
            "/answered"
        )
        .add({ name: "xxx", asuulgaId: this.asuulgaId, createdAt: new Date() });
      // console.log(fbAnswered);

      for (var category of this.categories) {
        // console.log(category.name);
        var catDoc = fbAnswered.collection("/categories/").doc(category.id);
        catDoc.set({ name: category.name, createdAt: new Date() });

        for (var question of category.questions) {
          // console.log(question.name);
          var questionDoc = catDoc.collection("/questions").doc(question.id);
          questionDoc.set(question);
          if (question.score) {
            allScore = allScore + question.score;
          }

          for (var answer of question.answers) {
            if (
              question.useranswer != null &&
              question.useranswer.answerId === answer.id
            ) {
              answer.answered = true;
            }
            if (answer.correct) {
              answer.scored = parseInt(question.score);
              allScored = allScored + answer.scored;
            }
            console.log(
              answer.id,
              answer.answered,
              answer.correct,
              question.score,
              answer.scored
            );
            questionDoc.collection("answers").doc(answer.id).set(answer);
            // console.log(answer.name, answer.answered, answer.correct, answer.scored, question.score);
          }
        }
      }

      fbAnswered
        .update({
          allScore: allScore,
          allScored: allScored,
        })
        .then(() => {
          this.$router.push({
            name: "ZResultPage",
            params: {
              asuulgaId: this.asuulgaId,
              resultId: fbAnswered.id,
              userId: this.$cookies.get("qa_" + this.asuulgaId + "_user"),
            },
          });
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
    },
    _getUserId() {
      return this.$cookies.get("qa_" + this.asuulgaId + "_user");
    },
    _getSelectionColor(question) {
      if (
        this.selectedQuestion != null &&
        this.selectedQuestion.id === question.id
      ) {
        return "primary";
      } else {
        if (question.answered) return "warning";
        else return "grey lighten-1";
      }
    },

    answerDefaultQuestion(question, answer, category) {
      console.log(question, answer, category);
      var userId = "qa_" + this.asuulgaId + "_user";
      console.log(this.$cookies.get(userId));

      // if (this.$cookies.get(userId) != null) {
      //   var newUserRef = fb.db
      //     .collection("asuulga")
      //     .doc(this.asuulgaId)
      //     .collection("users")
      //     .doc(this.$cookies.get(userId));
      //   newUserRef.update(this.userProps);
      // }
      var questionRef = null;
      if (category != null) {
        questionRef = fb.db
          .collection(
            "asuulga/" +
              this.asuulgaId +
              "/categories/" +
              category.id +
              "/questions"
          )
          .doc(question.id);
      } else {
        questionRef = fb.db
          .collection("asuulga/" + this.asuulgaId + "/questions")
          .doc(question.id);
      }

      var votedUserRef = questionRef
        .collection("votedusers")
        .doc(this.$cookies.get(userId));
      var answerRef = questionRef.collection("answers").doc(answer.id);
      answerRef.update({ votedCount: fb.firestore.FieldValue.increment(1) });

      if (question.useranswer != null && question.useranswer.answer != null) {
        question.useranswer.answer.update({
          votedCount: fb.firestore.FieldValue.increment(-1),
        });
      }
      console.log("-----------------------");
      console.log(answer.name);
      votedUserRef.set({
        userId: this.$cookies.get(userId),
        user: fb.db
          .collection("asuulga/" + this.asuulgaId + "/users")
          .doc(this.$cookies.get(userId)),
        // userName:this.userName,
        guest: true,
        date: new Date(),
        // isCorrect: answer.correct,
        answer: answerRef,
        answerId: answer.id,
        answertype: false,
        correct: null,
      });
      // TODO DOOGII
    },
    async createNewUser() {
      var qaRef = fb.db.collection("asuulga").doc(this.asuulgaId);
      var userId = "qa_" + this.asuulgaId + "_user";

      if (
        this.$cookies.get(userId) == null ||
        this.$cookies.get(userId) === undefined
      ) {
        var newUserRef = qaRef.collection("users").doc();
        var tmpUser = {
          createdAt: new Date(),
          saved: false,
          savednew: false,
          usertype: false,
        };
        // if (this.$route.query.yuneljd && this.$route.query.yuneljd != "") {
        //   tmpUser.globalId = this.$route.query.yuneljd;
        // }
        // if (this.userProps != null && this.userProps != undefined) {
        //   tmpUser = Object.assign(this.userProps, { createdAt: new Date() });
        // }
        newUserRef.set(tmpUser);

        this.$cookies.set(userId, newUserRef.id);
      } else {
        var newUserRef2 = qaRef
          .collection("users")
          .doc(this.$cookies.get(userId));
        if (this.$route.query.yuneljd && this.$route.query.yuneljd !== "") {
          newUserRef2.update({ globalId: this.$route.query.yuneljd });
        }
        newUserRef2.get().then((value) => {
          this.userProps = value.data();
        });
      }
    },

    _prevQuestion() {
      this.currentIndex--;
      this.selectedQuestion = this.category.questions[this.currentIndex];
    },
    _nextQuestion() {
      // if(this.category.question.length==(this.selectedQuestion))
      var index = this.category.questions.indexOf(this.selectedQuestion);
      if (this.category.questions.length > index + 1) {
        this.selectedQuestion = this.category.questions[index + 1];
      }
      this.currentIndex++;
    },
    _selectedQuestion(qindex) {
      this.selectedQuestion = this.category.questions[qindex];
      this.currentIndex = qindex;
    },
    _getIFrameUrl(asuulga) {
      return (
        "<iframe frameborder='0' width='100%' height='569' allowfullscreen='true' mozallowfullscreen='true' webkitallowfullscreen='true'" +
        asuulga.slideUrl +
        " </iframe>"
      );
    },
    _viewQuestion() {
      console.log("xxx");
    },
    _tab_selected(index) {
      this.selectedTabIndex = index;
    },

    _getQuestionsOfCategory(cat) {
      console.log(
        "asuulga/" + this.asuulgaId + "/categories/" + cat.id + "/questions"
      );
      fb.db
        .collection(
          "asuulga/" + this.asuulgaId + "/categories/" + cat.id + "/questions"
        )
        .orderBy("createdAt", "asc")
        .onSnapshot((querySnapshot) => {
          cat.questions = [];
          querySnapshot.forEach((doc) => {
            const question = doc.data();
            question.id = doc.id;
            question.category = cat.name;
            question.statData = null;
            question.datacollection = null;

            question.id = doc.id;
            question.answers = [];
            question.answered = false;
            question.currentAnswerActive = false;
            question.answeredCount = null;
            question.useranswer = null; // OORCHLOGDDOG DATAG OMNO NI ZAAVAL TODORHOILJ UTGA ONOOH

            question.answers = [];
            console.log(doc.ref);
            // this._getAnswers(question, doc.ref);

            doc.ref
              .collection("answers")
              .orderBy("createdAt", "asc")
              .onSnapshot((querySnapshot) => {
                question.answers = [];
                querySnapshot.forEach((doc) => {
                  const answer = doc.data();
                  answer.id = doc.id;
                  question.answers.push(answer);
                });

                doc.ref
                  .collection("votedusers")
                  .doc(this._getUserId())
                  .onSnapshot((doc) => {
                    question.useranswer = null;
                    if (doc.exists) {
                      question.useranswer = doc.data();
                      question.useranswer.id = doc.id;
                      question.answered = true;
                      console.log(
                        "Comming from the server ",
                        question.id,
                        question.useranswer.answerId
                      );
                      // if(question.answered!=true && this.category!=null)
                      //     this.category.answeredCount++;
                      // question.answered=true;
                      // if(question.multichoice==true){
                      //     if(question.answeredCount==null)
                      //     question.answeredCount=0;
                      //     question.answeredCount++;
                      // }
                    }
                  });
              });

            // if (
            //   object.divided == false &&
            //   object.group != null &&
            //   object.group != undefined
            // ) {
            //   //
            // } else {
            //   if (object.divided == true) {
            //     object.subquestions = [];
            //     this._getSubquestions(
            //       object,
            //       fb.db.doc(
            //         "asuulga/" + this.asuulgaId + "/categories/" + cat.id
            //       ),
            //       doc.ref
            //     );
            //   } else {
            //     if (object.answertype == true) {
            //       object.useranswers = [];
            //       //this._getQuestionAnswers(object, doc.ref);
            //     } else {
            //       object.answers = [];
            //       console.log(doc.ref);
            //       this._getAnswers(object, doc.ref);
            //     }
            //   }
            cat.questions.push(question);
            if (this.selectedQuestion == null) this.selectedQuestion = question;
            // }
          });
        });
    },
    async _findCategories() {
      var _this = this;
      var asuulga = fb.db.collection("asuulga").doc(this.asuulgaId);
      asuulga
        .get()
        .then(function (doc) {
          if (doc.exists) {
            _this.asuulga = doc.data();
            console.log("Document data:", doc.data());
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
        });

      fb.db
        .collection("asuulga/" + this.asuulgaId + "/categories")
        .orderBy("createdAt", "asc")
        .onSnapshot((querySnapshot) => {
          this.categories = [];
          querySnapshot.forEach((catDoc) => {
            const cat = catDoc.data();
            cat.id = catDoc.id;
            cat.questions = [];
            this._getQuestionsOfCategory(cat);
            this.categories.push(cat);
            if (this.categories.length === 1) {
              this.category = this.categories[0];
            }
          });
        });
    },
  },
};
</script>
<style scoped>
.v-main {
  background-color: #f0ebf7;
}
.quizBorder {
  border-style: solid;
  border-color: #e89e57;
  border-width: 15px;
  border-left-width: 15px;
  border-right-width: 15px;
  border-top-width: 30px;
  border-bottom-width: 30px;
}
</style>
